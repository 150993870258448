<template>
  <div>
    <!-- BUTTONS -->
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="createNewCoupon"
      :save_loading="loading_save"
    />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="mb-2">
        <v-card-text>
          <Form :info.sync="formData" />
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>
<script>
import Form from "./components/Form";
import { STORE_COUPON } from "@/core/services/store/general/coupons.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: {
    Form,
    Buttons,
  },
  data() {
    return {
      formData: null,
      loading_save: false,
      valid: true,
    };
  },
  methods: {
    /**
     * return campus page
     * @method cancel
     */
    cancel() {
      this.$router.push("/admin/coupons");
    },

    /**
     * Create new Coupon
     * @method createNewCoupon
     * @returns 'createNewCoupon' object whit data
     */
    async createNewCoupon() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.loading_save = true;

        const data = {
          ...this.formData,
        };

        if (data.courses) {
          const id_courses = [];
          data.courses?.forEach(course => {
            id_courses.push(course.id);
          });

          data.courses = id_courses.join();
          data.categories = data.categories.join();
        }

        const response = await this.$store.dispatch(STORE_COUPON, data);
        if (response) {
          this.loading_save = false;
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });

          setTimeout(() => {
            this.$router.push(
              `/admin/coupon/edit/${response.data.coupon.couponId}`
            );
          }, 2000);
        } else {
          this.loading_save = false;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("COUPONS.TITLE"),
        route: "/admin/coupons",
      },
      { title: this.$t("GENERAL.NEW") },
    ]);
  },
};
</script>
